<template>
  <div>
    <formulario-faixa
      v-if="formularioFaixaAberto"
      ref="formularioFaixa"
      :escopo-faixas="form.escopoFaixas"
      @fechar-formulario="fecharFormularioFaixa"
    />
    <template v-if="!formularioFaixaAberto">
      <div class="d-flex mb-8">
        <div class="titulo-pagina">
          {{ tituloFormulario }}
        </div>
      </div>

      <v-tabs
        v-model="indiceAba"
        class="abas-padrao"
        background-color="primary"
        color="primary"
      >
        <v-tab>
          {{ $t('modulos.escopo_servico.formulario.abas.dados_escopo') }}
        </v-tab>
        <v-tab>
          {{ $t('modulos.escopo_servico.formulario.abas.faixas') }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="indiceAba">
        <v-tab-item>
          <dados-escopo
            ref="dados-escopo"
            :form="form"
          />
        </v-tab-item>
        <v-tab-item>
          <faixas
            ref="faixas"
            :form="form"
            @abrir-formulario="abrirFormularioFaixa"
          />
        </v-tab-item>
      </v-tabs-items>

      <v-divider class="my-5" />

      <div class="d-flex align-center justify-end">
        <botao-padrao
          outlined
          color="secondary"
          class="mr-2"
          @click="cancelar"
        >
          <v-icon>$mdiCancel</v-icon>
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>
        <botao-padrao
          @click="confirmacaoSalvar"
        >
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.salvar') }}
        </botao-padrao>
      </div>
    </template>
  </div>
</template>
<script>
import EscopoServicoService from '@common/services/cadastros/EscopoServicoService.js';
import { EscopoServicoModel } from '@common/models/cadastros/EscopoServicoModel.js';
import DadosEscopo from './components/DadosEscopo.vue';
import Faixas from './components/Faixas.vue';
import FormularioFaixa from './components/FormularioFaixa.vue';
import helpers from '@common/utils/helpers';
export default {
  emits: ['abrir-formulario'],
  components: { DadosEscopo, Faixas, FormularioFaixa },
  props: ['id'],
  data() {
    return {
      formularioFaixaAberto: false,
      indiceAba: 0,
      dadosEscopoValido: false,
      form: new EscopoServicoModel({}),
    };
  },
  computed: {
    tabelaFaixaValido: function () {
      return this.form.escopoFaixas.length > 0;
    },
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.escopo_servico.titulos.editar');
      return this.$t('modulos.escopo_servico.titulos.novo');
    },
  },
  mounted() {
    if (this.id) {
      helpers.redirecionarSemPermissao(this,'Escopo', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'Escopo', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');

      EscopoServicoService.buscar(this.id)
        .then((res) => {
          this.form = new EscopoServicoModel(res.data);
          this.dadosEscopoValido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.escopo_servico.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    validarFormulario: async function(){
      this.indiceAba = 0;
      await new Promise(res => setTimeout(res, 300));
      const validarDados = this.$refs['dados-escopo'].$refs.form.validate();
      if(!validarDados){
        this.indiceAba = 0;
        this.toastAlerta(this.$t('modulos.escopo_servico.validacoes.formulario_invalido'));
        return false;
      }
      if(!this.tabelaFaixaValido){
        this.indiceAba = 1;
        this.toastAlerta(this.$t('modulos.escopo_servico.validacoes.faixa_invalido'));
        return false;
      }
      return true;
    },
    confirmacaoSalvar: async function () {
      if(!await this.validarFormulario()) return;
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      EscopoServicoService.salvar(this.form.request)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.escopo_servico.cadastro_sucesso`));
          this.$router.push({ name: 'escopo-servico' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'escopo-servico' });
      });
    },
    abrirFormularioFaixa: function (item) {
      this.formularioFaixaAberto = true;
      this.$nextTick(() => {
        this.$refs.formularioFaixa.abrirFormulario(item);
      });
    },
    fecharFormularioFaixa: function () {
      this.formularioFaixaAberto = false;
    },
  },
};
</script>
