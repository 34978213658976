import { DropdownModel } from '../geral/DropdownModel';
import _ from 'lodash';
export class EscopoServicoModel {
  constructor({
    id,
    codigo,
    nome,
    acreditacaoId,
    tipoCalibracao,
    locaisExecucoes,
    escopoFaixas = [],
    escopoCalibracoes = [],
  }) {
    this.id = id;
    this.codigo = codigo;
    this.nome = nome;
    this.acreditacaoId = acreditacaoId;
    if (tipoCalibracao) this.tipoCalibracao = new DropdownModel(tipoCalibracao);
    if (locaisExecucoes)
      this.locaisExecucoes = new DropdownModel(locaisExecucoes);
    this.escopoFaixas = escopoFaixas.map((faixa) => {
      return { ...faixa, flagJaAdicionado: true };
    });
    if (escopoCalibracoes)
      this.escopoCalibracoes = new DropdownModel(escopoCalibracoes);
  }
  get request() {
    let retorno = _.cloneDeep(this);
    retorno.escopoCalibracoes = retorno.escopoCalibracoes?.map((e) => {
      return {
        tipoCalibracaoId: e.value,
      };
    });

    retorno.escopoFaixas = retorno.escopoFaixas?.map((faixa) => {
      return {
        ...faixa,
        unidadeMedidaId: faixa.unidadeMedida?.value ?? faixa.unidadeMedida?.id,
        unidadeMedidaCMCId:
          faixa.unidadeMedidaCMC?.value ?? faixa.unidadeMedidaCMC?.id,
        id: faixa.flagJaAdicionado ? faixa.id : null,
      };
    });

    retorno.locaisExecucoesIds = retorno.locaisExecucoes?.map((e) => {return e.value});

    return retorno;
  }
}
