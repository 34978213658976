<template lang="">
  <div class="mt-5">
    <div class="d-flex align-center justify-space-between mb-3">
      <div>
        <botao-padrao
          v-if="podeExcluir"
          class="my-2"
          outlined
          color="danger"
          @click="excluir"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>
      <botao-padrao @click="abrirNovo">
        {{ $t('modulos.escopo_servico.formulario.faixas.adicionar_faixa') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      sem-paginacao
    >
      <template v-slot:item.acoes="{ item }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <botao-padrao
              text
              color="secondary"
              @click="editarFaixa(item.item)"
            >
              {{ $t('geral.botoes.editar') }}
            </botao-padrao>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao>
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
export default {
  props: {
    form: {},
  },
  data() {
    return {
      opcoes: {
        tipoCMC: [],
      },
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
            sortable: false,
          },
          {
            value: 'descricao',
            text: this.$t('modulos.escopo_servico.formulario.faixas.faixa'),
            sortable: true,
          },
          {
            value: 'amplitudeMinima',
            text: this.$t(
              'modulos.escopo_servico.formulario.faixas.amplitudeMin'
            ),
            sortable: true,
            formatter: (valor) => {
              if (valor || valor == 0) {
                return `${helpers.formatarNumeroComPrecisao(valor)}`;
              }
            },
          },
          {
            value: 'amplitudeMaxima',
            text: this.$t(
              'modulos.escopo_servico.formulario.faixas.amplitudeMax'
            ),
            sortable: true,
            formatter: (valor) => {
              if (valor || valor == 0) {
                return `${helpers.formatarNumeroComPrecisao(valor)}`;
              }
            },
          },
          {
            value: 'unidadeMedida',
            text: this.$t(
              'modulos.escopo_servico.formulario.faixas.unidadeMedida'
            ),
            formatter: (valor, linha) => {
              if (linha && linha.unidadeMedida) {
                return linha.unidadeMedida.simbolo;
              }
            },
            sortable: true,
          },
          {
            value: 'tipoCMC',
            text: this.$t('modulos.escopo_servico.formulario.faixas.cmc'),
            sortable: true,
            formatter: (valor) => {
              if (valor)
                return this.opcoes.tipoCMC.find((v) => v.value === valor)?.text;
            },
          },
          {
            value: 'unidadeMedidaCMC',
            text: this.$t(
              'modulos.escopo_servico.formulario.faixas.unidadeCmc'
            ),
            formatter: (valor, linha) => {
              if (linha && linha.unidadeMedidaCMC) {
                return linha.unidadeMedidaCMC.simbolo;
              }
            },
            sortable: true,
          },
          {
            value: 'valorCMCInicial',
            text: this.$t(
              'modulos.escopo_servico.formulario.faixas.valor_cmc_inicial'
            ),
            sortable: true,
            formatter: (v) => helpers.formatarNumeroComPrecisao(v)
          },
          {
            value: 'valorCMCFinal',
            text: this.$t(
              'modulos.escopo_servico.formulario.faixas.valor_cmc_final'
            ),
            sortable: true,
            formatter: (v) => helpers.formatarNumeroComPrecisao(v)
          },
          {
            value: 'formulaCMC',
            text: this.$t(
              'modulos.escopo_servico.formulario.faixas.formula_cmc'
            ),
            sortable: true,
          },
        ],
        selecionados: [],
      },
    };
  },
  computed: {
    podeExcluir() {
      return this.tabela.selecionados != 0;
    },
    faixasComparacao() {
      if (this.tabela.dados.length > 0) {
        return this.tabela.dados.map((item) => {
          return {
            id: item.id,
            amplitudeMinima: item.amplitudeMinima,
            amplitudeMaxima: item.amplitudeMaxima,
            unidadeMedida: item.unidadeMedida,
          };
        });
      }
      return [];
    },
  },
  watch: {
    'form.escopoFaixas': {
      handler() {
        this.tabela.dados = this.form.escopoFaixas;
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    await this.buscarTipoCMC();
    this.tabela.dados = this.form.escopoFaixas;
  },
  methods: {
    abrirNovo: function () {
      this.$emit('abrir-formulario', {
        item: null,
        faixasComparacao: this.faixasComparacao,
      });
    },
    editarFaixa: function (item) {
      this.$emit('abrir-formulario', {
        item,
        faixasComparacao: this.faixasComparacao,
      });
    },

    excluir: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.form.escopoFaixas.splice(
            this.form.escopoFaixas.indexOf(element),
            1
          );
        });
        this.tabela.selecionados = [];
      });
    },
    formatarFaixa: function (value) {
      if (value) {
        return value.replace(/\./g, ',');
      }
    },
    buscarTipoCMC: async function () {
      await EnumeradorService.buscar('EnumTipoCMC').then((res) => {
        this.opcoes.tipoCMC = res;
      });
    },
  },
};
</script>
