var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex mb-8"},[_c('div',{staticClass:"titulo-pagina"},[_vm._v(" "+_vm._s(_vm.tituloFormulario)+" ")])]),_c('v-form',{ref:"form",staticClass:"row"},[_c('v-form',{ref:"amplitudeForm",staticClass:"col-12 col-md-4 d-flex align-top"},[_c('input-text',{staticClass:"flex-fill",attrs:{"label":_vm.$t('modulos.escopo_servico.formulario.faixas.formulario.amplitude'),"placeholder":_vm.$t(
            'modulos.escopo_servico.formulario.faixas.formulario.amplitude_min'
          ),"obrigatorio":"","regras-personalizadas":_vm.regraAmplitude(),"type":"number"},on:{"input":_vm.valitarAmpliturde},model:{value:(_vm.form.amplitudeMinima),callback:function ($$v) {_vm.$set(_vm.form, "amplitudeMinima", $$v)},expression:"form.amplitudeMinima"}}),_c('span',{staticClass:"mx-2 mt-11"},[_vm._v("a")]),_c('input-text',{staticClass:"flex-fill mt-5",attrs:{"placeholder":_vm.$t(
            'modulos.escopo_servico.formulario.faixas.formulario.amplitude_max'
          ),"obrigatorio":"","regras-personalizadas":_vm.regraAmplitude(),"type":"number"},on:{"input":_vm.valitarAmpliturde},model:{value:(_vm.form.amplitudeMaxima),callback:function ($$v) {_vm.$set(_vm.form, "amplitudeMaxima", $$v)},expression:"form.amplitudeMaxima"}})],1),_c('input-unidade-medida',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t(
          'modulos.escopo_servico.formulario.faixas.formulario.unidade_medida'
        ),"multiplos":false,"obrigatorio":""},model:{value:(_vm.form.unidadeMedida),callback:function ($$v) {_vm.$set(_vm.form, "unidadeMedida", $$v)},expression:"form.unidadeMedida"}}),_c('input-text',{staticClass:"col-12 col-md-2",attrs:{"label":_vm.$t('modulos.escopo_servico.formulario.faixas.formulario.descricao'),"max":150,"obrigatorio":""},model:{value:(_vm.form.descricao),callback:function ($$v) {_vm.$set(_vm.form, "descricao", $$v)},expression:"form.descricao"}}),_c('input-select',{staticClass:"col-12 col-md-3",attrs:{"options":_vm.opcoes.tipoCMC,"label":_vm.$t('modulos.escopo_servico.formulario.faixas.formulario.tipo_cmc'),"obrigatorio":""},model:{value:(_vm.form.tipoCMC),callback:function ($$v) {_vm.$set(_vm.form, "tipoCMC", $$v)},expression:"form.tipoCMC"}}),_c('input-unidade-medida',{staticClass:"col-12 col-md-3",attrs:{"listar-unidade-medida-por-grandezas-fisicas-ids":!!_vm.form.unidadeMedida?.grandezaFisica?.id,"grandezas-fisicas-ids":[_vm.form.unidadeMedida?.grandezaFisica?.id],"disabled":_vm.form.tipoCMC == 'Percentual',"label":_vm.$t('modulos.escopo_servico.formulario.faixas.formulario.unidade_cmc'),"multiplos":false,"titulo-modal":'modulos.escopo_servico.formulario.faixas.modal.unidade_cmc',"obrigatorio":""},model:{value:(_vm.form.unidadeMedidaCMC),callback:function ($$v) {_vm.$set(_vm.form, "unidadeMedidaCMC", $$v)},expression:"form.unidadeMedidaCMC"}}),_c('input-text',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t(
          'modulos.escopo_servico.formulario.faixas.formulario.valor_cmc_inicial'
        ),"type":"number","obrigatorio":!_vm.desabilitar.valorCMCInicial,"disabled":_vm.desabilitar.valorCMCInicial},model:{value:(_vm.form.valorCMCInicial),callback:function ($$v) {_vm.$set(_vm.form, "valorCMCInicial", $$v)},expression:"form.valorCMCInicial"}}),_c('input-text',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t(
          'modulos.escopo_servico.formulario.faixas.formulario.valor_cmc_final'
        ),"type":"number","obrigatorio":!_vm.desabilitar.valorCMCFinal,"disabled":_vm.desabilitar.valorCMCFinal},model:{value:(_vm.form.valorCMCFinal),callback:function ($$v) {_vm.$set(_vm.form, "valorCMCFinal", $$v)},expression:"form.valorCMCFinal"}}),_c('input-text',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t('modulos.escopo_servico.formulario.faixas.formulario.formula_cmc'),"disabled":_vm.desabilitar.formulaCMC,"max":100,"obrigatorio":!_vm.desabilitar.formulaCMC},model:{value:(_vm.form.formulaCMC),callback:function ($$v) {_vm.$set(_vm.form, "formulaCMC", $$v)},expression:"form.formulaCMC"}})],1),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"d-flex align-center justify-end"},[_c('botao-padrao',{staticClass:"mr-2",attrs:{"outlined":"","color":"secondary"},on:{"click":_vm.cancelar}},[_c('v-icon',[_vm._v("$mdiCancel")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.cancelar'))+" ")],1),_c('botao-padrao',{on:{"click":_vm.confirmacaoSalvar}},[_c('v-icon',[_vm._v("$mdiContentSaveOutline")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.salvar'))+" ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }