import { DropDownIdNomeSimbolo } from '../geral/DropDownIdNomeSimbolo';
import _ from 'lodash';
export class EscopoServicoFaixaModel {
  constructor({
    id,
    descricao,
    amplitudeMinima,
    amplitudeMaxima,
    unidadeMedida = null,
    tipoCMC,
    unidadeMedidaCMC = null,
    valorCMCInicial,
    valorCMCFinal,
    formulaCMC,
    
  }) {
    this.id = id;
    this.descricao = descricao;
    this.amplitudeMinima = amplitudeMinima;
    this.amplitudeMaxima = amplitudeMaxima;
    this.unidadeMedida = unidadeMedida
    if (unidadeMedida)
      this.unidadeMedida = new DropDownIdNomeSimbolo(unidadeMedida);
    this.tipoCMC = tipoCMC;
    this.unidadeMedidaCMC = unidadeMedidaCMC
    if (unidadeMedidaCMC)
      this.unidadeMedidaCMC = new DropDownIdNomeSimbolo(unidadeMedidaCMC);
    this.valorCMCInicial = valorCMCInicial;
    this.valorCMCFinal = valorCMCFinal;
    this.formulaCMC = formulaCMC;
    this.dadosDescricao = { amplitudeMinima:'', amplitudeMaxima:'', unidadeMedida:''};
  }

  get request() {
    let retorno = _.cloneDeep(this);
    retorno.unidadeMedida = {
      id: retorno.unidadeMedida.value,
      nome: retorno.unidadeMedida.text,
      simbolo: retorno.unidadeMedida.simbolo,
    };

    retorno.unidadeMedidaCMC = {
      id: retorno.unidadeMedidaCMC.value,
      nome: retorno.unidadeMedidaCMC.text,
      simbolo: retorno.unidadeMedidaCMC.simbolo,
    };

    return retorno;
  }
}
