<template lang="">
  <div>
    <v-form ref="form">
      <div class="mt-2 row">
        <input-text
          v-model="form.codigo"
          class="col-12 col-md-2"
          :label="$t('modulos.escopo_servico.formulario.dados_escopo.codigo')"
          :max="50"
          obrigatorio
        />
        <input-text
          v-model="form.nome"
          class="col-12 col-md-2"
          :label="$t('modulos.escopo_servico.formulario.dados_escopo.nome')"
          :max="100"
          obrigatorio
        />
        <input-select
          v-model="form.acreditacaoId"
          :label="
            $t('modulos.escopo_servico.formulario.dados_escopo.acreditacao')
          "
          :placeholder="$t('geral.inputs.selecione')"
          class="col-12 col-md-2"
          obrigatorio
          :options="opcoes.acreditacao"
        />
        <input-select
          v-model="form.escopoCalibracoes"
          :label="
            $t('modulos.escopo_servico.formulario.dados_escopo.tipo_calibracao')
          "
          :placeholder="$t('geral.inputs.selecione')"
          class="col-12 col-md-4"
          obrigatorio
          multiplos
          :options="opcoes.tipoCalibracao"
          retornar-objeto
        />

        <input-select
          v-model="form.locaisExecucoes"
          :label="
            $t('modulos.escopo_servico.formulario.dados_escopo.local_execucao')
          "
          :placeholder="$t('geral.inputs.selecione')"
          class="col-12 col-md-2"
          :options="opcoes.localExecucao"
          multiplos
          obrigatorio
          retornar-objeto
        />
      </div>
    </v-form>
  </div>
</template>
<script>
import TipoCalibracaoService from '@/common/services/cadastros/TipoCalibracaoService.js';
import LocalExecucaoService from '@/common/services/cadastros/LocalExecucaoService.js';
import AcreditacaoService from '@/common/services/cadastros/AcreditacaoService.js';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import { mapGetters } from 'vuex';
export default {
  props: {
    form: {},
  },
  data() {
    return {
      opcoes: {
        tipoCalibracao: [],
        localExecucao: [],
        acreditacao: [],
      },
    };
  },
  mounted() {
    this.buscarTipoCalibracao();
    this.buscarLocalExecucao();
    this.buscarAcreditacao();
  },
  computed:{
    ...mapGetters('Autenticacao', ['participanteLogadoConta']),
  },
  methods: {
    buscarTipoCalibracao: function () {
      TipoCalibracaoService.listar().then((res) => {
        let tipoCalibracao = res.data
        if(!this.participanteLogadoConta){
          tipoCalibracao = tipoCalibracao.filter(t => t.acessivelClientes == true)
        }
        this.opcoes.tipoCalibracao = new DropdownModel(tipoCalibracao);
      });
    },
    buscarLocalExecucao: function () {
      LocalExecucaoService.listar().then((res) => {
        this.opcoes.localExecucao = new DropdownModel(res.data);
      });
    },
    buscarAcreditacao: function () {
      AcreditacaoService.listar().then((res) => {
        this.opcoes.acreditacao = new DropdownModel(res.data.items, 'codigo');
      });
    },
  },
};
</script>
